import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from '../templates/blog.module.css'

export const query = graphql`
  query {
    markdownRemark(fileAbsolutePath: {regex: "/wiki\/End-User-Licence-Agreement.md$/"}) {
      fileAbsolutePath
      html
    }
  }
`

export default function EULA({data}) {
  const {markdownRemark} = data
  const {html} = markdownRemark
  const title = 'End User Licence Agreement'
  return (
    <Layout>
      <SEO title={title} />
      <div className={styles.blogContainer}>
        <h1>{title}</h1>
        <div
          className={styles.blogContent}
          dangerouslySetInnerHTML={{__html: html}}
        />
      </div>
    </Layout>
  )
}
